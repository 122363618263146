import { ref } from '@vue/composition-api'
import router from '@/router'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useMairies() {
  const { errorToast, successToast } = useAlertNotification()

  const mairie = ref([])
  const loader = ref(false)
  const mairieProcess = ref(false)
  const mairieSuccess = ref(false)
  const mairies = ref([])
  const errors = ref('')

  // Liste des Mairies qui ont au moins un certficat
  const getListeMairies = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-list-mairies')
      if (response.data.success === true) {
        loader.value = false
        mairies.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const listTownHallAtLeastAtCertificateType = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/list-mairie-atleast-certificat_type')
      if (response.data.success === true) {
        loader.value = false
        mairies.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  // Trouver une mairie par son Id
  const findMairieById = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-mairie-by-id/${router.currentRoute.params.id}`)
      if (response.data.success === true) {
        loader.value = false
        mairie.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  // Trouver une mairie par son slug

  const findMairieBySlug = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-mairie-by-slug/${router.currentRoute.params.slug}`)
      if (response.data.success === true) {
        loader.value = false
        mairie.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  // Créer ou Modifier une mairie
  const storeOrUpdateMairie = async data => {
    try {
      errors.value = ''
      mairieProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('id')) {
        const response = await axiosClient.patch(`/update-mairie/${data.id}`, data)
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          mairieSuccess.value = true
          mairieProcess.value = false
          await getListeMairies()
        }
      } else {
        const response = await axiosClient.post('/create-mairie', data)
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          mairieSuccess.value = true
          mairieProcess.value = false
          await getListeMairies()
        }
      }
    } catch (error) {
      mairieProcess.value = false
      mairieSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorToast('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const deleteMairie = async id => {
    try {
      errors.value = ''
      mairieProcess.value = true
      const response = await axiosClient.delete(`/delete-mairie/${id}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)
        mairieSuccess.value = true
        mairieProcess.value = false
        await getListeMairies()
      }
    } catch (error) {
      mairieProcess.value = false
      mairieSuccess.value = false
      loader.value = false
      errors.value = ''
      errorToast('Oups! Erreur', error.response.data.message)
    }
  }
  return {
    errors,
    mairie,
    mairies,
    mairieProcess,
    loader,
    getListeMairies,
    listTownHallAtLeastAtCertificateType,
    findMairieById,
    findMairieBySlug,
    storeOrUpdateMairie,
    mairieSuccess,
    deleteMairie,
  }
}
