import { ref } from '@vue/composition-api'
import router from '@/router'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useCertificateTypes() {
  const { errorToast, successToast } = useAlertNotification()

  const certificateType = ref([])
  const loader = ref(false)
  const certificateTypeProcess = ref(false)
  const certificateTypeSuccess = ref(false)
  const certificateTypes = ref([])
  const errors = ref('')

  // Liste des CertificateTypes
  const getListeCertificateTypes = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-list-type-certificates')
      if (response.data.success === true) {
        loader.value = false
        certificateTypes.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  // Liste des CertificateTypes
  const listCertificateTypesByMairie = async mairieId => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-type-certificate-by-mairie/${mairieId}`)
      if (response.data.success === true) {
        loader.value = false
        certificateTypes.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  // Trouver une certificateType par son Id
  const findCertificateTypeById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-type-certificate-by-id/${id}`)
      if (response.data.success === true) {
        loader.value = false
        certificateType.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  // Créer ou Modifier une certificateType
  const storeOrUpdateCertificateType = async data => {
    try {
      errors.value = ''
      certificateTypeProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('id')) {
        const response = await axiosClient.patch(`/update-type-certificate/${data.id}`, data)
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          certificateTypeSuccess.value = true
          certificateTypeProcess.value = false
          await listCertificateTypesByMairie()
        }
      } else {
        const response = await axiosClient.post('/create-type-certificate', data)
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          certificateTypeSuccess.value = true
          certificateTypeProcess.value = false
          await listCertificateTypesByMairie()
          await router.push({ name: 'espace-admin.list-certificats' })
        }
      }
    } catch (error) {
      certificateTypeProcess.value = false
      certificateTypeSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorToast('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const deleteCertificateType = async id => {
    try {
      errors.value = ''
      certificateTypeProcess.value = true
      const response = await axiosClient.delete(`/delete-type-certificate/${id}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)
        certificateTypeSuccess.value = true
        certificateTypeProcess.value = false
        await listCertificateTypesByMairie()
      }
    } catch (error) {
      certificateTypeProcess.value = false
      certificateTypeSuccess.value = false
      loader.value = false
      errors.value = ''
      errorToast('Oups! Erreur', error.response.data.message)
    }
  }
  return {
    errors,
    certificateType,
    certificateTypes,
    certificateTypeProcess,
    listCertificateTypesByMairie,
    loader,
    getListeCertificateTypes,
    findCertificateTypeById,
    storeOrUpdateCertificateType,
    certificateTypeSuccess,
    deleteCertificateType,
  }
}
