<template>
  <b-row class="justify-content-center">
    <b-col md="9">
      <b-overlay
        :show="requestCertificateProcess"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-lg
        rounded="sm"
      >
        <b-card class="mb-0 my-1">
          <form-wizard
            color="#7367F0"
            title="Demande de certificat"
            subtitle="Veuillez remplir ce formaire pour faire votre demande"
            finish-button-text="Soumettre la demande"
            back-button-text="Précédent"
            next-button-text="Suivant"
            button-size="sm"
            class="steps-transparent mb-3 font-size"
            justified
            @on-complete="formSubmitted"
          >
            <!-- Choix mairie -->
            <tab-content
              title="Choix de la mairie"
              :before-change="validationForm"
            >
              <validation-observer
                ref="accountRules"
                tag="form"
              >
                <b-row class="justify-content-md-center">
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Choix de la mairie
                    </h5>
                    <small class="text-muted">
                      A quelle mairie souhaiteriez-vous envoyer votre demande?
                    </small>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="mairie"
                      rules="required"
                    >
                      <b-form-group
                        label="Mairie"
                        label-for="mairie"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          id="mairie"
                          v-model="form.mairie_id"
                          label="mairie_name"
                          :state="errors.length > 0 ? false : null"
                          :options="mairies"
                          placeholder="Choisir une mairie"
                          :reduce="(mairies) => mairies.id"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="float-right"
                      onclick="history.back()"
                    >
                      <feather-icon icon="ArrowLeftCircleIcon" />
                      Annuler
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Choix type de certificat -->
            <tab-content
              title="Choix du type de certificat"
              :before-change="validationFormInfo"
            >
              <validation-observer
                ref="infoRules"
                tag="form"
              >
                <b-row class="justify-content-md-center">
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Type de certificat
                    </h5>
                    <small
                      class="text-muted"
                    >Quel certificat aimeriez-vous demander?</small>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="type de certificat"
                      rules="required"
                    >
                      <b-form-group
                        label="Type de certificat"
                        label-for="certificate_type_id"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          id="certificate_type_id"
                          v-model="form.certificate_type_id"
                          label="name"
                          :state="errors.length > 0 ? false : null"
                          :options="certificateTypes"
                          placeholder="Choisir un type de certificat"
                          :reduce="(certificateTypes) => certificateTypes.id"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="float-right"
                      onclick="history.back()"
                    >
                      <feather-icon icon="ArrowLeftCircleIcon" />
                      Annuler
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Informations personnelles -->

            <tab-content
              title="Informations personnelles"
              :before-change="validationInformationsPersonelles"
            >
              <validation-observer
                ref="personalInformationsRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Informations de l'utilisateur
                    </h5>
                    <small class="text-muted">Ceci concerne vos informations pour la demande.</small>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Nom"
                      label-for="lastname"
                    >
                      <b-form-input
                        id="lastname"
                        v-model="infoUser.last_name"
                        disabled
                        placeholder="98 Borough bridge Road, Birmingham"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Prénoms"
                      label-for="firstname"
                    >
                      <b-form-input
                        id="firstname"
                        v-model="infoUser.first_name"
                        disabled
                        placeholder="Borough bridge"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Téléphone"
                      label-for="phonenumber"
                    >
                      <b-form-input
                        id="phonenumber"
                        v-model="infoUser.phonenumber"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Numéro d'identification personnelle"
                      label-for="npi"
                    >
                      <b-form-input
                        id="npi"
                        v-model="infoUser.npi"
                        disabled
                        placeholder="Birmingham"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Email"
                      label-for="email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="email"
                          v-model="form.email"
                          :state="errors.length > 0 ? false:null"
                          type="email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Date de naissance"
                      label-for="birthday"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="date de naissance"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="form.birthday"
                          class="form-control"
                          placeholder="Date de naissance"
                          :config="config"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Lieu de Naissance"
                      label-for="birthplace"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="lieu de naissance"
                        rules="required"
                      >
                        <b-form-input
                          id="birthplace"
                          v-model="form.birthplace"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Profession"
                      label-for="profession"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="profession"
                        rules="required"
                      >
                        <b-form-input
                          id="profession"
                          v-model="form.profession"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="float-right"
                      onclick="history.back()"
                    >
                      <feather-icon icon="ArrowLeftCircleIcon" />
                      Annuler
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Informations additionnelles -->

            <tab-content
              title="Informations additionelles"
              :before-change="validationFormAdditionalInformations"
            >
              <validation-observer
                ref="additionalInformationsRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Veuillez entrer ces differentes informations supplémentaires
                    </h5>
                  </b-col>
                  <b-col md="4">

                    <b-form-group
                      label="Ancien lieu de résidence"
                      label-for="old_residence"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="ancienne résidence"
                        rules="required"
                      >
                        <b-form-input
                          id="old_residence"
                          v-model="form.old_residence"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-form-group
                      label="Durée pendant laquelle vous avez vécu à cette adresse"
                      label-for="duree"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="durée"
                        rules="required|integer"
                      >
                        <b-form-input
                          id="duree"
                          v-model.number="form.duree"
                          type="number"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="Unité"
                      label-for="unite_temps"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="unité"
                        rules="required"
                      >
                        <b-form-select
                          id="unite_temps"
                          v-model="form.unite_temps"
                          :options="options"
                          :state="errors.length > 0 ? false : null"
                          :reduce="(options) => options.value"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">

                    <b-form-group
                      label="Nouveau lieu de résidence"
                      label-for="new_residence"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="nouvelle résidence"
                        rules="required"
                      >
                        <b-form-input
                          id="new_residence"
                          v-model="form.new_residence"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Date de sortie de l'ancien domicile"
                      label-for="movedate"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="date ancien domicile"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="form.movedate"
                          class="form-control"
                          placeholder="Date de sortie de l'ancien domicile"
                          :config="config"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Date d'entrée dans le nouveau domicile"
                      label-for="moveindate"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="date nouveau domicile"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="form.moveindate"
                          class="form-control"
                          placeholder="Date d'entrée dans le nouveau domicile"
                          :config="config"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="float-right"
                      onclick="history.back()"
                    >
                      <feather-icon icon="ArrowLeftCircleIcon" />
                      Annuler
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Chargement des pièces-->
            <tab-content
              title="Chargement des pièces"
              :before-change="validationStepFilesForm"
            >
              <validation-observer
                ref="fileRules"
                tag="form"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Chargement des pièces
                    </h5>
                    <small
                      class="text-muted"
                    >Veullez charger vos pièces.</small>
                  </b-col>
                  <b-col md="12">
                    <b-table
                      striped
                      hover
                      size="sm"
                      responsive
                      :busy="loader"
                      class="position-relative"
                      :items="items"
                      :fields="fields"
                    >
                      <template #table-busy>
                        <div class="d-flex justify-content-center mb-3">
                          <b-spinner
                            style="width: 2rem; height: 2rem"
                            variant="primary"
                            label="Large Spinner"
                            type="grow"
                            class="mt-2"
                          />
                          <span class="sr-only">Chargement en cours...</span>
                        </div>
                      </template>

                      <template #cell(actions)="data">
                        <div class="text-center">
                          <b-button
                            v-if="
                              allPiece.length !== 0 &&
                                allPiece[data.index] !== undefined &&
                                allPiece[data.index].filename !== undefined
                            "
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-tooltip.hover.bottom="'Modifier la pièce'"
                            v-b-modal.modal-update
                            variant="warning"
                            size="sm"
                            class="btn-icon rounded-circle ml-1"
                            @click="getPieceUpdate(data.item)"
                          >
                            <feather-icon icon="EditIcon" />
                          </b-button>
                          <b-button
                            v-if="
                              allPiece.length !== 0 &&
                                allPiece[data.index] !== undefined &&
                                allPiece[data.index].filename !== undefined
                            "
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-tooltip.hover.bottom="'Modifier la pièce'"
                            variant="success"
                            size="sm"
                            class="btn-icon rounded-circle ml-1"
                            @click="toggleModal"
                            @click.prevent="getPdfFile(allPiece[data.index].pdfFile)"
                          >
                            <feather-icon icon="EyeIcon" />
                          </b-button>
                          <b-button
                            v-else
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-tooltip.hover.bottom="'Charger la pièce'"
                            v-b-modal.add-piece
                            variant="primary"
                            size="sm"
                            class="btn-icon rounded-circle ml-1"
                            @click="getPiece(data.item)"
                          >
                            <feather-icon icon="LinkIcon" />
                          </b-button>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="float-right"
                      onclick="history.back()"
                    >
                      <feather-icon icon="ArrowLeftCircleIcon" />
                      Annuler
                    </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

          </form-wizard>
        </b-card>
      </b-overlay>
    </b-col>
    <b-modal
      id="modal-display"
      ref="myModal"
      size="xl"
      button-size="sm"
      title="Pièce jointe"
      ok-only
      ok-title="Fermer"
    >
      <b-card style="height: 500px;">
        <iframe
          style="height: 500px; width: 100%;"
          :src="urlPdf"
        />
      </b-card>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="add-piece"
      ref="my-modal"
      no-close-on-backdrop
      button-size="sm"
      title="Ajouter une pièce jointe"
      ok-title="Ajouter"
      cancel-title="Fermer"
      cancel-variant="secondary"
      ok-variant="success"
      @ok="handleOk"
      @show="clearPiece"
    >
      <validation-observer ref="accountFilesRules">
        <form @submit.prevent="handleSubmitModal">
          <b-form-group
            label="Numéro de la pièce"
            label-for="piece_number"
          >
            <validation-provider
              #default="{ errors }"
              name="numéro de pièce"
              rules="required"
            >
              <b-form-input
                id="piece_number"
                v-model="formPiece.numero_piece"
                size="sm"
                :state="errors.length > 0 ? false : null"
                placeholder="Numéro de la pièce"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Pièce"
            label-for="fichier"
            label-size="sm"
          >
            <validation-provider
              #default="{ errors }"
              name="pièce"
              rules="required"
            >
              <b-form-file
                id="fichier"
                ref="file-input"
                v-model="formPiece.filename"
                accept=".pdf"
                size="sm"
                placeholder="Choisir un fichier"
                @change="handleImageSelected"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <b-button
                v-if="uploadProcess"
                variant="success"
                disabled
                block
                size="sm"
                class="mt-1"
              >
                <b-spinner small />
                Chargement...
              </b-button>
            </validation-provider>
          </b-form-group>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-update"
      ref="my-modal"
      no-close-on-backdrop
      button-size="sm"
      title="Ajouter une pièce jointe"
      ok-title="Ajouter"
      cancel-title="Fermer"
      cancel-variant="secondary"
      ok-variant="success"
      @ok="handleOk"
    >
      <validation-observer ref="accountFilesRules">
        <form @submit.prevent="handleSubmitModal">
          <b-form-group
            label="Numéro de la pièce"
            label-for="piece_number"
          >
            <validation-provider
              #default="{ errors }"
              name="numéro de pièce"
              rules="required"
            >
              <b-form-input
                id="piece_number"
                v-model="formPiece.numero_piece"
                size="sm"
                :state="errors.length > 0 ? false : null"
                placeholder="Numéro de la pièce"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Pièce"
            label-for="fichier"
            label-size="sm"
          >
            <validation-provider
              #default="{ errors }"
              name="pièce"
              rules="required"
            >
              <b-form-file
                id="fichier"
                ref="file-input"
                v-model="formPiece.filename"
                accept=".pdf"
                size="sm"
                placeholder="Choisir un fichier"
                @change="handleImageSelected"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <b-button
                v-if="uploadProcess"
                variant="success"
                disabled
                block
                size="sm"
                class="mt-1"
              >
                <b-spinner small />
                Chargement...
              </b-button>
            </validation-provider>
          </b-form-group>
        </form>
      </validation-observer>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies, import/extensions
import { French } from 'flatpickr/dist/l10n/fr.js'

import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
  BFormFile,
  BTable,
  VBTooltip,
  BButton,
  BSpinner,
  BCard,
  BModal,
  BFormSelect,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required, email } from '@validations'
// eslint-disable-next-line import/no-cycle
import useMairies from '@/services/super-admin/mairieService'
import {
  onBeforeMount, reactive, ref, watch,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useCertificateTypes from '@/services/admin/certificateTypeService'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'

// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import useRequestCertificates from '@/services/requestCertificate/requestCertificate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BButton,
    BSpinner,
    BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    flatPickr,
    vSelect,
    BTable,
    BOverlay,
    BCard,
    BFormInvalidFeedback,
    BModal,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const myModal = ref()
    const toggleModal = () => {
      myModal.value.show()
    }

    const { listTownHallAtLeastAtCertificateType, mairies } = useMairies()
    const user = JSON.parse(store.state.authStore.user)

    const {
      storeRequestCertificate, requestCertificateSuccess, requestCertificateProcess, errors,
    } = useRequestCertificates()
    const {
      listCertificateTypesByMairie,
      certificateTypes,
      certificateTypeProcess,
      findCertificateTypeById,
      certificateType,
      loader,
    } = useCertificateTypes()
    const {
      imageUrl,
      imageFile,
      handleImageSelected,
      uploadFilePdf,
      fileName,
      uploadProcess,
      // deleteFile,
    } = useImageUpload()
    const options = [
      { value: null, text: 'Sélectionner' },
      { value: 'mois', text: 'mois' },
      { value: 'an(s)', text: 'année' },
    ]
    const piece = ref({})
    const allPiece = reactive([])
    const PDF = ref(null)
    const pieceId = ref('')
    const formPiece = reactive({
      numero_piece: '',
      filename: null,
    })
    const infoUser = reactive({
      last_name: '',
      first_name: '',
      phonenumber: '',
      npi: '',
    })
    const clearPiece = () => {
      piece.value = {}
      imageUrl.value = ''
      fileName.value = ''
      // pieceId.value = null
      PDF.value = null
      formPiece.numero_piece = ''
    }
    const urlPdf = ref('')
    const checkData = reactive([])
    const checkDataPiece = reactive([])
    const oldImage = ref('')

    const upload = async () => {
      const result = imageFile.value instanceof File
      if (result) {
        const data = new FormData()
        data.append('fichier', imageFile.value)
        await uploadFilePdf(data)
        PDF.value = fileName.value
        urlPdf.value = ''
        urlPdf.value = imageUrl.value
        // await deleteFile(oldImage.value)
      }
    }
    const getPiece = item => {
      piece.value.id = item.id
      pieceId.value = item.id
      piece.value.label = item.label
      // eslint-disable-next-line radix
      const id = parseInt(item.id)
      const checkPiece = allPiece.find(doc => doc.id === id)
      if (checkPiece === undefined) allPiece.push(piece.value)
    }
    const getPieceUpdate = item => {
      const check = ref([])
      check.value = allPiece.find(value => value.id === item.id)

      formPiece.numero_piece = check.value.numero_piece
      formPiece.filename = check.value.filename
      oldImage.value = check.value.filename
      piece.value.id = item.id
      pieceId.value = item.id
      piece.value.label = item.label
      imageUrl.value = check.value.pdfFile

      // eslint-disable-next-line radix
      const id = parseInt(item.id)
      const checkPiece = allPiece.find(doc => doc.id === id)
      if (checkPiece === undefined) allPiece.push(piece.value)
    }
    const getPdfFile = item => {
      urlPdf.value = ''
      urlPdf.value = item
    }

    const submitPiece = async () => {
      await upload()
      const pieceUpdated = {
        id: pieceId.value,
        document_type_id: pieceId.value,
        numero_piece: formPiece.numero_piece,
        filename: PDF.value || formPiece.filename,
        pdfFile: imageUrl.value,
      }
      // eslint-disable-next-line
      const indexOfObject = allPiece.findIndex((object) => object.id === pieceId.value);
      allPiece.splice(indexOfObject, 1)
      allPiece.push(pieceUpdated)
      pieceId.value = ''
      clearPiece()
      imageFile.value = null
    }
    const form = reactive({
      mairie_id: null,
      email: null,
      birthday: null,
      new_residence: null,
      old_residence: null,
      unite_temps: null,
      birthplace: '',
      profession: '',
      certificate_type_id: null,
      attachements: allPiece,
    })
    const fields = [
      { key: 'label', label: 'Pièces', sortable: true },
      { key: 'actions', label: 'Actions', sortable: true },
    ]
    const items = ref([])
    watch(
      () => _.cloneDeep(form.mairie_id),
      async () => {
        await listCertificateTypesByMairie(form.mairie_id)
      },
    )
    watch(
      () => _.cloneDeep(form.certificate_type_id),
      async () => {
        await findCertificateTypeById(form.certificate_type_id)
        items.value = certificateType.value.document_types
      },
    )
    onBeforeMount(async () => {
      await listTownHallAtLeastAtCertificateType()
      infoUser.first_name = user.first_name
      infoUser.last_name = user.last_name
      infoUser.phonenumber = user.phonenumber
      infoUser.npi = user.npi
      form.email = user.email
      form.birthday = user.birthday
      form.birthplace = user.birthplace
      form.profession = user.profession
    })
    const storeRequest = async () => {
      await storeRequestCertificate({ ...form })
    }
    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M Y',
      altInput: true,
      enableTime: false,
      dateFormat: 'Y-m-d',
      locale: French, // locale for this instance only
    }
    return {
      config,
      getPiece,
      uploadProcess,
      infoUser,
      mairies,
      getPdfFile,
      urlPdf,
      allPiece,
      storeRequest,
      options,
      piece,
      form,
      loader,
      errors,
      submitPiece,
      handleImageSelected,
      clearPiece,
      formPiece,
      getPieceUpdate,
      imageUrl,
      checkDataPiece,
      checkData,
      fields,
      items,
      toggleModal,
      myModal,
      store,
      requestCertificateSuccess,
      requestCertificateProcess,
      certificateTypeProcess,
      certificateTypes,
    }
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    validationFilesForm() {
      return new Promise((resolve, reject) => {
        if (this.form.fichiers.length >= 2) {
          resolve(true)
        } else {
          reject()
        }
      })
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.accountFilesRules.validate().then(success => {
        if (success) {
          // Push the name to submitted names
          this.submitPiece()
          this.$refs['file-input'].reset()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
        }
      })
    },
    async formSubmitted() {
      await this.storeRequest()
      if (this.requestCertificateSuccess) this.sweetAlertSuccess('Votre demande a été soumise!')
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationInformationsPersonelles() {
      return new Promise((resolve, reject) => {
        this.$refs.personalInformationsRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAdditionalInformations() {
      return new Promise((resolve, reject) => {
        this.$refs.additionalInformationsRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationStepFilesForm() {
      this.checkData = []
      this.checkDataPiece = []
      Object.keys(this.allPiece).forEach(key => {
        if (this.allPiece.length !== 0 && this.allPiece[key] !== undefined && this.allPiece[key].numero_piece !== undefined) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.allPiece[key].hasOwnProperty('numero_piece')) {
            const check = this.checkData.indexOf(this.allPiece[key].numero_piece)
            const checkPiece = this.checkDataPiece.indexOf(this.allPiece[key].filename)
            if (check === -1) this.checkData.push(this.allPiece[key].numero_piece)
            if (checkPiece === -1) this.checkDataPiece.push(this.allPiece[key].filename)
          }
        }
      })

      return new Promise((resolve, reject) => {
        if (this.allPiece.length === this.items.length && this.checkData.length === this.items.length) {
          resolve(true)
        } else if (this.checkDataPiece.length !== this.items.length) {
          this.sweetAlertError(`Vous avez chargé ${this.checkDataPiece.length} sur ${this.items.length} pièce(s). Il vous reste ${this.items.length - this.checkDataPiece.length} pièce(s)`)
          reject()
        } else {
          reject()
          this.sweetAlertError('Vous avez répété un numéro de pièce')
        }
      })
    },
    sweetAlertError(text) {
      this.$swal({
        title: 'Error!',
        text,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-danger btn-sm',
        },
        buttonsStyling: false,
      })
    },
    sweetAlertSuccess(text) {
      this.$swal({
        title: 'Validation!',
        text,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-success btn-sm',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
